window.abreMenu = function () {
	var menu = document.getElementById('menu');
	var body = document.getElementsByClassName('body');
	menu.style.left = '0px';
	body.style.overflow = 'hidden';
}
window.fechaMenu = function () {
	var menu = document.getElementById('menu');
	var body = document.getElementsByClassName('body');
		menu.style.left = '-1600px';
		body.style.overflow = 'auto';
}